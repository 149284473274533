import React, { useState, useEffect, useCallback } from "react";
import {
  Breadcrumb,
  Button,
  Badge,
  Table,
  Typography,
  Input,
  Divider,
  Tag,
  Space,
  Tooltip,
  Menu,
  Dropdown,
  Col,
  Row,
  Statistic,
  Progress,
} from "antd";
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useAuth0 } from "../../react-auth0-spa";
import config from "../../api_config.json";
import { orgFilters } from "../../config";
import { useAppContext } from "../../context";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import format from "date-fns/format";
import subHours from "date-fns/subHours";
import subDays from "date-fns/subDays";
import { Link, useHistory } from "react-router-dom";
import OrgLinks from "../OrgDetails/OrgLinks";
import { useLocalStorage } from "@rehooks/local-storage";
import { decodeStr } from "../../utils";
import groupBy from "lodash.groupby";
import { isFuture, parseISO } from "date-fns";

const { Title } = Typography;

const query = `
query GetActiveUsers($afterDate: Date!, $beforeDate: Date!) {
  sessions (
    first: 10000,
    where: {
      createdAt: { greaterThan: $afterDate }
      AND: { createdAt: { lessThan: $beforeDate } }
    }) {
    edges {
      node {
        objectId
        createdAt
        user {
          objectId
          org_pointer { 
            objectId
          	nome
            createdAt
            tipo_istanza
            stato
          }
        }
      }
    }
  }
}`
  .replace(/\r?\n|\r/g, "")
  .replace(/\t/g, " ");

const queryOrgs = `
query Orgs {
  organizzaziones(first: 10000, order:createdAt_DESC) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }    
    edges{
      node{
        objectId
        nome
        plan
        stato
        tipo_istanza
        stripe_created_at
        giotto
        ofc
        data_scadenza
        data_scadenza_giotto
        percent_services_used
        email
        createdAt
      }
    }
  }
}`
  .replace(/\r?\n|\r/g, "")
  .replace(/\t/g, " ");

const Stats = (props) => (
  <Row gutter={16}>
    <Col span={12}>
      <Statistic
        title={`Users (${props.timeRange})`}
        value={props.activeUsers}
        loading={props.activeUsers === -1}
      />
    </Col>
    <Col span={12}>
      <Statistic
        title={`Orgs (${props.timeRange})`}
        value={props.activeOrgs}
        loading={props.activeOrgs === -1}
      />
    </Col>
    <Col span={12} offset={12}>
      <Progress
        strokeLinecap="butt"
        percent={Math.floor((props.activeOrgs / props.totalOrgs) * 100)}
      />
    </Col>
  </Row>
);

const Orgs = (props) => {
  const { activeFilter } = props.match.params;
  const { isProd } = useAppContext();
  const env = isProd ? "prod" : "dev";
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [items, setItems] = useLocalStorage(`${env}.orgs`, []);
  const [orgsOnline30dCount, setOrgsOnline30dCount] = useState(-1);
  const [usersOnline30dCount, setUsersOnline30dCount] = useState(-1);
  const [orgsOnline24hCount, setOrgsOnline24hCount] = useState(-1);
  const [usersOnline24hCount, setUsersOnline24hCount] = useState(-1);
  const [orgsOnline60mCount, setOrgsOnline60mCount] = useState(-1);
  const [usersOnline60mCount, setUsersOnline60mCount] = useState(-1);

  const { getTokenSilently } = useAuth0();
  const history = useHistory();

  const graphqlUrl = `${config.baseUrl[env]}/graphql`;

  const fetchData = useCallback(
    async (_isProd, params = {}) => {
      try {
        const token = await getTokenSilently();
        let responseData;

        if (Object.keys(params).length > 0) {
          let url = `${config.baseUrl[env]}/orgs`;
          url = `${url}?` + new URLSearchParams(params);

          const response = await fetch(url, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          responseData = await response.json();
        } else {
          // to get All orgs, a quick way to speed up the query is to only switch this query
          // without filters
          responseData = await axios({
            method: "post",
            url: graphqlUrl,
            data: {
              query: queryOrgs,
              variables: {},
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        }

        return responseData;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    [env, getTokenSilently, graphqlUrl]
  );

  const fetchStats = useCallback(
    async (_isProd, params = {}) => {
      try {
        const token = await getTokenSilently();

        const now = new Date().toISOString();
        const yesterday = new Date(subHours(new Date(now), 24)).toISOString();
        const lastMonth = new Date(subDays(new Date(now), 30)).toISOString();
        const oneHourAgo = new Date(subHours(new Date(now), 2)).toISOString();

        const sessions30d = axios({
          method: "post",
          url: graphqlUrl,
          data: {
            query,
            variables: {
              beforeDate: new Date(now),
              afterDate: new Date(lastMonth),
            },
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const sessions24h = axios({
          method: "post",
          url: graphqlUrl,
          data: {
            query,
            variables: {
              beforeDate: new Date(now),
              afterDate: new Date(yesterday),
            },
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const sessions60m = axios({
          method: "post",
          url: graphqlUrl,
          data: {
            query,
            variables: {
              beforeDate: new Date(now),
              afterDate: new Date(oneHourAgo),
            },
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const responses = await Promise.all([
          sessions24h,
          sessions60m,
          sessions30d,
        ]);

        return responses;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    [getTokenSilently, graphqlUrl]
  );

  const applyFilter = (filterName) => {
    setLoading(true);
    history.push(`/clients/${filterName}`);
    fetchData(isProd, {
      filterName,
    }).then((response) => {
      setItems(response.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);

    const params = {};
    if (activeFilter) {
      params.filterName = activeFilter;
    }

    fetchData(isProd, params).then((response) => {
      setItems(response.data);
      setLoading(false);
    });
  }, [activeFilter, fetchData, isProd, setItems]);

  useEffect(() => {
    fetchStats(isProd).then((responses) => {
      const uniqueUsers24h = groupBy(responses[0].data, "user.objectId");
      const uniqueUsers60m = groupBy(responses[1].data, "user.objectId");
      const uniqueUsers30d = groupBy(responses[2].data, "user.objectId");

      const uniqueOrgs24h = groupBy(
        responses[0].data,
        "user.org_pointer.objectId"
      );
      const uniqueOrgs60m = groupBy(
        responses[1].data,
        "user.org_pointer.objectId"
      );
      const uniqueOrgs30d = groupBy(
        responses[2].data,
        "user.org_pointer.objectId"
      );

      Object.entries(uniqueOrgs30d).forEach(([orgId, sessions]) => {
        const isSuspect =
          sessions[0].user?.org_pointer?.stato === "disattive" ||
          sessions[0].user?.org_pointer?.tipo_istanza === "demo";

        const isTest = sessions[0].user?.org_pointer?.nome?.includes("E2E");

        if (!isSuspect || isTest) {
          return;
        }

        console.log(
          orgId,
          sessions[0].user.org_pointer.objectId,
          sessions[0].user.org_pointer.tipo_istanza,
          sessions[0].user.org_pointer.stato,
          sessions[0].user.org_pointer.nome,
          sessions.length
        );
      });

      setOrgsOnline30dCount(Object.keys(uniqueOrgs30d).length);
      setUsersOnline30dCount(Object.keys(uniqueUsers30d).length);

      setOrgsOnline24hCount(Object.keys(uniqueOrgs24h).length);
      setUsersOnline24hCount(Object.keys(uniqueUsers24h).length);

      setOrgsOnline60mCount(Object.keys(uniqueOrgs60m).length);
      setUsersOnline60mCount(Object.keys(uniqueUsers60m).length);
    });
  }, [fetchStats, isProd]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            // setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          // if (searchInput) {
          //   searchInput.select()
          // }
        }, 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "objectId",
      key: "objectId",
      ...getColumnSearchProps("objectId"),
      render: (orgId) => {
        return <Link to={`/clients/${orgId}`}>{orgId}</Link>;
      },
    },
    {
      title: "Nome Azienda",
      dataIndex: "nome",
      key: "nome",
      ...getColumnSearchProps("nome"),
      render: (nome, record) => {
        return (
          <Link to={`/clients/${record.objectId}`}>{decodeStr(nome)}</Link>
        );
      },
      sorter: {
        compare: (a, b) => {
          if (a.nome < b.nome) {
            return -1;
          }
          if (a.nome > b.nome) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      title: "Istanza",
      key: "tipo_istanza",
      dataIndex: "tipo_istanza",
      filters: items
        .map((item) => item.tipo_istanza)
        .sort()
        .filter((value) => value)
        .filter(onlyUnique)
        .map((value) => {
          return {
            text: value,
            value,
          };
        }),
      onFilter: (value, record) => record.tipo_istanza?.indexOf(value) === 0,
      render: (tipoIstanza = "", record) => {
        if (!tipoIstanza) {
          return;
        }
        const date = record.giotto
          ? record.data_scadenza_giotto
          : record.data_scadenza;

        const colors = {
          free: "blue",
          demo:
            record.stato === "attiva"
              ? isFuture(parseISO(date))
                ? "green"
                : "volcano"
              : "volcano",
          production: "green",
        };

        return (
          <Tag color={colors[tipoIstanza]}>{tipoIstanza.toUpperCase()}</Tag>
        );
      },
    },
    {
      title: "Piano",
      dataIndex: "plan",
      key: "plan",
      filters: items
        .map((item) => item.plan)
        .sort()
        .filter((value) => value)
        .filter(onlyUnique)
        .map((value) => {
          return {
            text: value,
            value,
          };
        }),
      onFilter: (value, record) =>
        record.plan && record.plan.indexOf(value) === 0,
      render: (plan, record) => {
        if (plan) {
          return plan;
        } else if (record.tipo_licenza) {
          const mapping = {
            enterprise: "FULL",
            plus: "ENTRY",
            pro: "PRO",
          };

          return mapping[record.tipo_licenza]
            ? mapping[record.tipo_licenza]
            : record.tipo_licenza;
        } else {
          return "";
        }
      },
    },
    {
      title: "Data creazione",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value, record) => {
        console.log(record);
        let date = record.createdAt;

        if (date?.iso) {
          date = date.iso;
        }

        if (date) {
          return (
            <Tag color="geekblue">{format(new Date(date), "dd/MM/yyyy")}</Tag>
          );
        } else {
          return "";
        }
      },
    },
    {
      title: "Abbonato dal",
      dataIndex: "stripe_created_at",
      key: "stripe_created_at",
      render: (value, record) => {
        let date = record.stripe_created_at;

        if (date?.iso) {
          date = date.iso;
        }

        if (date) {
          return (
            <Tooltip title={record.giotto ? "Giotto" : "Eterno"}>
              <Tag color={record.giotto ? "geekblue" : "purple"}>
                {format(new Date(date), "dd/MM/yyyy")}
              </Tag>
            </Tooltip>
          );
        } else {
          return "";
        }
      },
    },
    {
      title: "Data Scadenza",
      dataIndex: "data_scadenza",
      key: "data_scadenza",
      render: (value, record) => {
        let date = record.giotto
          ? record.data_scadenza_giotto
          : record.data_scadenza;

        if (date?.iso) {
          date = date.iso;
        }

        if (date) {
          return (
            <Tooltip title={record.giotto ? "Giotto" : "Eterno"}>
              <Tag color={record.giotto ? "purple" : "geekblue"}>
                {format(new Date(date), "dd/MM/yyyy")}
              </Tag>
            </Tooltip>
          );
        } else {
          return "";
        }
      },
      sorter: {
        compare: (a, b) => {
          const date_a = a.giotto
            ? a.data_scadenza_giotto?.iso
            : a.data_scadenza?.iso;

          const date_b = b.giotto
            ? b.data_scadenza_giotto?.iso
            : b.data_scadenza?.iso;

          if (new Date(date_a) > new Date(date_b)) {
            return -1;
          }
          if (new Date(date_a) < new Date(date_b)) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      title: "% Servizi",
      dataIndex: "percent_services_used",
      key: "percent_services_used",
      sorter: {
        compare: (a, b) => {
          const valA = a.percent_services_used || 0;
          const valB = b.percent_services_used || 0;
          if (valA < valB) {
            return -1;
          }
          if (valA > valB) {
            return 1;
          }
          return 0;
        },
      },
      render: (value, record) => {
        if (value >= 0) {
          const x = Math.ceil(value);
          let color;
          if (x >= 75) {
            color = "red";
          } else if (x >= 50) {
            color = "orange";
          } else {
            color = "black";
          }
          return <span style={{ color }}>{x}%</span>;
        }
      },
    },
    {
      title: "Links",
      key: "email",
      dataIndex: "email",
      render: (_, record) => {
        return <OrgLinks org={record} />;
      },
    },
  ];

  function Filters(props) {
    const filtersMenu = (
      <Menu>
        {props.data.map((filter, i) => {
          return (
            <Menu.Item key={`menu-${i}`}>
              <div onClick={() => applyFilter(filter.filterName)}>
                {filter.title}
              </div>
            </Menu.Item>
          );
        })}
        <Menu.Item
          onClick={() => {
            setLoading(true);
            history.push(`/clients/`);
            fetchData(isProd).then((response) => {
              setItems(response.data);
              setLoading(false);
            });
          }}
          danger
        >
          Reset Filters
        </Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={filtersMenu}>
        <div className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <Title level={4} style={{ marginBottom: "40px" }}>
            Filtri <DownOutlined />
          </Title>
        </div>
      </Dropdown>
    );
  }

  return (
    <div style={{ margin: "16px 16px" }}>
      <div>{loading && <LinearProgress />}</div>
      <Breadcrumb style={{ marginBottom: "20px" }}>
        <Breadcrumb.Item>Clienti</Breadcrumb.Item>
        <Breadcrumb.Item>Tutti</Breadcrumb.Item>
      </Breadcrumb>
      <Divider />
      <div style={{ float: "right" }}>
        <Space>
          <Stats
            activeUsers={usersOnline30dCount}
            activeOrgs={orgsOnline30dCount}
            totalOrgs={items.length}
            timeRange="30d"
          />
          <Divider type="vertical" />
          <Stats
            activeUsers={usersOnline24hCount}
            activeOrgs={orgsOnline24hCount}
            totalOrgs={items.length}
            timeRange="24h"
          />
          <Divider type="vertical" />
          <Stats
            activeUsers={usersOnline60mCount}
            activeOrgs={orgsOnline60mCount}
            totalOrgs={items.length}
            timeRange="60min"
          />
        </Space>
      </div>
      <Title level={3} style={{ marginBottom: "40px" }}>
        <Space>
          Lista Organizzazioni
          <Badge overflowCount={5000} count={items.length}></Badge>
        </Space>
      </Title>
      <Filters data={orgFilters} />
      <Table
        dataSource={items}
        columns={columns}
        loading={loading && items.lenght === 0}
      />
    </div>
  );
};

export default Orgs;
